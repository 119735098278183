import React from "react";
import CurrencyFormat from "../../common/CurrencyFormat";
import { useRouter } from "next/router";
import { useStateContext } from "../../../context";
import { useCookie } from "next-cookie";
import InformationCircleIcon from "@heroicons/react/solid/InformationCircleIcon";
import Image from "next/image";
import CurrencyFormatPrice from "../../common/CurrencyFormatPrice";
import { InfoIcon } from "../../common/Icon";

const PackageCard = (props) => {
  const {
    detail = false,
    data,
    color = "secondary",
    loading,
    hideButton,
    token = null,
  } = props;

  const router = useRouter();
  const [state, setState] = useStateContext();
  const cookie = useCookie();
  const lat = cookie.get("lat") || router.query.lat || null;
  const lng = cookie.get("lng") || router.query.lng || null;
  const allowLocation = (lat && lng) !== undefined;

  const loggedInUser = state.user;

  const rawData = detail
    ? data
    : data.package_price.length !== 0 && data.package_price[0];
  const packages = detail
    ? rawData.package_list.package_feature
    : data.package_feature;
  const packagePrice = !allowLocation
    ? rawData.selling_info.price
    : rawData.package_state.length === 0
    ? rawData.selling_info.price
    : rawData.package_state[0].special_price;
  const handleCheckout = (id) => {
    const isLogin = !!loggedInUser;
    if (isLogin) {
      router.push(`/packages/${id}/checkout${token ? `?token=${token}` : ""}`);
    } else {
      setState({ ...state, loginModal: true });
      router.push(
        `/login?continue=${encodeURIComponent(window.location.href)}`
      );
    }
  };

  return (
    <div
      className="package-card-background"
      style={{
        backgroundColor:
          (data.package_list?.name?.toLowerCase() ||
            data.name?.toLowerCase()) === "standart"
            ? "#40BCFF"
            : "#AB4FFF",
      }}
    >
      <div
        className={`d-block package-card h-100 d-flex ${
          loading && "placeholder"
        } mt-4`}
      >
        <div className="flex-grow-1 d-flex flex-column justify-content-between">
          <div>
            <div className="text-start d-flex flex-column flex-grow-1">
              <div
                className={`d-flex flex-column gap-2 text-white`}
                style={{
                  padding: "10px",
                  borderRadius: "20px",
                  backgroundColor:
                    (data.package_list?.name?.toLowerCase() ||
                      data.name?.toLowerCase()) === "standart"
                      ? "#1C92D2"
                      : "#B377E9",
                }}
              >
                <div className="d-flex flex-md-row flex-column justify-content-between align-items-start align-items-md-center p-3 gap-2">
                  <div
                    className={`text-white gap-2 d-flex justify-content-between align-items-center`}
                  >
                    <div
                      className="bg-white rounded-circle d-flex justify-content-center"
                      style={{ width: "3.25em", height: "3.25em" }}
                    >
                      <Image
                        src={`/assets/${
                          detail
                            ? data.package_list.name.toLowerCase()
                            : data.name.toLowerCase()
                        }.svg`}
                        alt="package icon"
                        width={20}
                        height={20}
                      />
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center">
                      <span
                        className="font-roboto"
                        style={{ fontSize: "25px", fontWeight: "800" }}
                      >
                        {detail
                          ? data.package_list.name.toUpperCase()
                          : data.name.toUpperCase()}
                      </span>
                      {!hideButton && (
                        <span>
                          {rawData.duration_type === 0
                            ? "Sebulan"
                            : rawData.duration_type === 1
                            ? "Bulanan"
                            : rawData.duration_type === 2
                            ? "Tahunan"
                            : "N/A"}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column align-items-end">
                    <div className={`text-white d-flex flex-row`}>
                      <p
                        className="mb-0"
                        style={{ fontWeight: "500", fontSize: "15px" }}
                      >
                        IDR.
                      </p>

                      <p
                        className="mb-0 mx-1 font-roboto"
                        style={{
                          fontWeight: "800",
                          fontSize: "35px",
                          lineHeight: "1",
                        }}
                      >
                        {packagePrice <= 0 ? (
                          "Free"
                        ) : (
                          <CurrencyFormatPrice
                            currency=""
                            amount={packagePrice}
                          />
                        )}
                      </p>
                      <p
                        className="mb-0 font-roboto align-self-end"
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        {rawData.duration_type === 0
                          ? "Rb/1bulan"
                          : rawData.duration_type === 1
                          ? "Rb/6bulan"
                          : rawData.duration_type === 2
                          ? "Rb/1tahun"
                          : "N/A"}
                      </p>
                    </div>

                    {rawData.price !== rawData.selling_info.price && (
                      <div
                        className={`${
                          color === "primary" ? "text-white" : "text-primary"
                        } text-end d-flex align-items-end text-decoration-line-through`}
                      >
                        <p className="small-secondary-text mb-0 fw-bold">
                          {packagePrice <= 0 ? (
                            "Free"
                          ) : (
                            <CurrencyFormat
                              currency=""
                              amount={rawData.price}
                            />
                          )}
                          /
                        </p>
                        <p className="small-secondary-text mb-0">
                          {rawData.duration_type === 0
                            ? "1 Bulan"
                            : rawData.duration_type === 1
                            ? "6 Bulan"
                            : rawData.duration_type === 2
                            ? "1 Tahun"
                            : "N/A"}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`p-3 d-flex flex-column bg-white text-secondary`}
                  style={{ borderRadius: "15px" }}
                >
                  <ul>
                    {packages.map((pkg, i) => (
                      <li key={i}>
                        <p className="">
                          {pkg.value === 0 ? "Unlimited" : pkg.value} {pkg.name}
                        </p>
                      </li>
                    ))}
                  </ul>

                  {!hideButton && (
                    <>
                      <div
                        className="alert d-flex gap-2 align-items-center"
                        style={{
                          backgroundColor: "#E4F6FF",
                          fontWeight: "500",
                          color: "#1C92D2",
                          border: "1px solid #B2E5FF",
                        }}
                      >
                        <InfoIcon width={16} height={16} />
                        <div className="small-secondary-text">
                          Durasi Listing Sesuai dengan Masa Berlaku Paket.
                        </div>
                      </div>

                      <div className="d-flex flex-column gap-2">
                        {data.package_price.map((pkg, i) => (
                          <button
                            className={`btn rounded-pill 
                            ${
                              color === "primary"
                                ? i === 0
                                  ? "btn-warning"
                                  : "btn-outline-warning"
                                : i === 0
                                ? "btn-price-primary"
                                : "btn-price-outline-primary"
                            }
                          `}
                            onClick={() => handleCheckout(pkg.id)}
                            disabled={pkg.is_active}
                            style={{ fontSize: "12px", fontWeight: "600" }}
                          >
                            {i === 0 ? (
                              "Beli Sekarang"
                            ) : (
                              <span className="d-flex flex-column fst-italic">
                                <span>
                                  {pkg.selling_info.price.toLocaleString("id", {
                                    style: "currency",
                                    currency: "IDR",
                                    maximumFractionDigits: 0,
                                  })}{" "}
                                  /{" "}
                                  {pkg.duration_type === 0
                                    ? "1bulan"
                                    : pkg.duration_type === 1
                                    ? "6bulan"
                                    : pkg.duration_type === 2
                                    ? "1tahun"
                                    : "N/A"}
                                </span>

                                {pkg.price !== pkg.selling_info.price && (
                                  <span className="text-decoration-line-through small-secondary-text">
                                    {pkg.price.toLocaleString("id", {
                                      style: "currency",
                                      currency: "IDR",
                                      maximumFractionDigits: 0,
                                    })}{" "}
                                    /{" "}
                                    {pkg.duration_type === 0
                                      ? "1bulan"
                                      : pkg.duration_type === 1
                                      ? "6bulan"
                                      : pkg.duration_type === 2
                                      ? "1tahun"
                                      : "N/A"}
                                  </span>
                                )}
                              </span>
                            )}
                          </button>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
